import React, { Component } from 'react';
import VideoPlayer from 'react-video-js-player';
import "./video-aula.css"

class VideoApp extends Component {
    player = {}
    hideControlsPlay = ['seekbar']
    state = {
        video: {
            src: "http://homolog.gergo.com.br/videos/treinamento-em-ergonomia.mp4?_s=vp-1.4.3",
            poster: "https://clinimedjoinville.com.br/wp-content/uploads/2018/03/122931-OQHVXT-748-min.jpg"
        }
    }

    onPlayerReady(player){
        // console.log("Player is ready: ", player);
        this.player = player;
    }

    onVideoPlay(duration){
        // console.log("Video played at: ", duration);
    }

    onVideoPause(duration){
        // console.log("Video paused at: ", duration);
    }

    onVideoTimeUpdate(duration){
        // console.log("Time updated: ", duration);
    }

    onVideoSeeking(duration){
        // console.log("Video seeking: ", duration);
    }

    onVideoSeeked(from, to){
        // console.log(`Video seeked from ${from} to ${to}`);
    }

    onVideoEnd(){
        console.log("Video ended");
    }

    rederVideo(srcVideo, posterVideo) {
        this.setState({video : {src:srcVideo, poster: posterVideo}})
    }

    render() {
      const {
        start,
        onVideoEnd,
        srcVideo,
        posterVideo
      } = this.props;
        return (
            
            <div className='player-wrapper'>
                {start !== null && start !== '' && start !== undefined &&
                    <VideoPlayer
                        // hideControls = {this.hideControlsPlay}
                        className = 'react-player'
                        controls={true}
                        src={srcVideo}
                        autoplay={start}
                        // src={this.state.video.src}
                        poster={posterVideo}
                        // poster={this.state.video.poster}
                        // onReady={this.onPlayerReady.bind(this)}
                        // onPlay={this.onVideoPlay.bind(this)}
                        // onPause={this.onVideoPause.bind(this)}
                        // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                        // onSeeking={this.onVideoSeeking.bind(this)}
                        // onSeeked={this.onVideoSeeked.bind(this)}
                        onEnd={onVideoEnd.bind(this)}
                    />
                } 
                {(start === null || start === '' || start === undefined) &&
                    <VideoPlayer
                        // hideControls = {this.hideControlsPlay}
                        className = 'react-player'
                        controls={true}
                        src={srcVideo}
                        // src={this.state.video.src}
                        poster={posterVideo}
                        // poster={this.state.video.poster}
                        // onReady={this.onPlayerReady.bind(this)}
                        // onPlay={this.onVideoPlay.bind(this)}
                        // onPause={this.onVideoPause.bind(this)}
                        // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                        // onSeeking={this.onVideoSeeking.bind(this)}
                        // onSeeked={this.onVideoSeeked.bind(this)}
                        onEnd={onVideoEnd.bind(this)}
                    /> 
                }
            </div>
        );
    }
}
export default VideoApp;