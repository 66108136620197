export const Material = theme => ({
  container: {
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    padding: '0vw 3vw'
  },
  subTitle: {
    borderBottom: "2px"
  },
  formControl: {
    marginBottom: '10px'
  },

  textFieldData : {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginBottom:'10px',
    width: '100%',
    border: '1px solid #c0c0c0',
    borderRadius: '2px',
    borderWidth: '1px',
    height: '55px'
  },
  
  empresaNome: {
    //backgroundColor: '#d6d6d6', 
    padding: '5px',
    border: '1px solid black',
    borderRadius: 5, 
    marginBottom: '20px'
  },
  textfield: {
    margin: '0px 10px 10px 0px'
  }
})