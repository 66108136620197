export const Material = theme => ({

  formControl: {
    marginBottom: '10px'
  },
  textfield: {
    margin: '0px 10px 10px 0px',
  },
  container: {
    height: '100%'
  },
  button: {
    margin: '10px 0px 0px 20px',
    padding: '5px',
    color: '#fff'
  },
  homeButtonYesNo: {
    height: '56px',
    marginLeft: '10px',
    marginRight:'10px',
    marginBottom:'10px'
  },

  homeButton: {
    margin: "10px"
  },
  homeButtonNasa: {
    margin: "10px",
    width: "186px"
  },
  table: {
    margin: '10px 0px', 
    padding: '10px', 
    //border: '1px solid #555',
    backgroundColor: "#d6d6d6" ,
    borderRadius: 5
  },
  tableRow: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  NENHUMA: {
    backgroundColor: '#42f46e',
    width: '20%',
  },
  LEVE: {
    backgroundColor: '#f4d341',
    width: '20%'
  },
  MODERADO: {
    backgroundColor: '#f57c00',
    width: '20%'
  },
  FORTE: {
    backgroundColor: '#ff4621',
    width: '20%',
    color: '#fff'
  },
  EXTREMO: {
    backgroundColor: '#6d4c41',
    width: '20%',
    color: '#fff'
  },
  sensoSelected: {
    border: '3px solid black'
  },
  toolTip: {
    color: 'yellow'
  },
  titleDialog: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },  
})