import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Material } from './styles';
import api from '../../services/api'
import './time-version.css'

class TimeVersion extends Component {
    state = {
        hours:null,
        hoursfaltante: null
    }

    getHours = async() => {
      try{
        const {data} = await api.get(`/getHours`);
        // console.log('data', data.object)
        await this.setState({hours:data.object});
        let dateNow = new Date();
       let hoursFalt = this.diferencaHoras(dateNow.getHours() + ':'+ dateNow.getMinutes(), data.object);
      //  console.log('hoursFalt',hoursFalt)
       await this.setState({hoursfaltante:hoursFalt});
      }catch(err){
        console.log(err)
      }
    }

    diferencaHoras(horaInicial, horaFinal){ 
      console.log('horaInicial',horaInicial)
      console.log('horaFinal',horaFinal)
      // Tratamento se a hora inicial é menor que a final 
      if( ! this.isHoraInicialMenorHoraFinal(horaInicial, horaFinal) )
      { 
        let aux = horaFinal; 
        horaFinal = horaInicial; 
        horaInicial = aux; 
      } 
      let hIni = horaInicial.split(':'); 
      let hFim = horaFinal.split(':'); 
      let horasTotal = parseInt(hFim[0], 10) - parseInt(hIni[0], 10); 
      let minutosTotal = parseInt(hFim[1], 10) - parseInt(hIni[1], 10); 
      if(minutosTotal < 0)
      { 
        minutosTotal += 60; horasTotal -= 1; 
      } 
      horaFinal = this.completaZeroEsquerda(horasTotal) + ":" + this.completaZeroEsquerda(minutosTotal); 
      return horaFinal; 
    } 

    completaZeroEsquerda(horasTotal){
      return ("00" + horasTotal).slice(-2);
    }

    isHoraInicialMenorHoraFinal(horaInicial, horaFinal) { 
      let horaIni = horaInicial.split(':'); 
      let horaFim = horaFinal.split(':'); 
      
      // Verifica as horas. Se forem diferentes, é só ver se a inicial 
      // é menor que a final. 
      let hIni = parseInt(horaIni[0], 10); 
      let hFim = parseInt(horaFim[0], 10); 
      if(hIni !== hFim) 
        return hIni < hFim; 
      
      // Se as horas são iguais, verifica os minutos então. 
      let mIni = parseInt(horaIni[1], 10); 
      let mFim = parseInt(horaFim[1], 10); 
      if(mIni !== mFim) 
        return mIni < mFim; 
    }

    componentWillMount = () => {
      setInterval(() => {
        this.getHours();
      }, 3000);
    }

    render() {
        // eslint-disable-next-line
        const { hours, hoursfaltante } = this.state;
        return (
            <div className="corpo">
              { hours != null && <span className="version">
                O Sistema Interno irá ficar indisponível após o horario {hours} por 30 min para manutenção. Tempo restante {hoursfaltante}. 
              </span>}
             </div>
        );
    }
}
export default (withStyles(Material)(TimeVersion));