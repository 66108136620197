import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';

import UsuarioActions from '../../store/ducks/usuario';

import { Material, Form, SwipeImage, GridImage } from './styles';
import ergonomic2 from '../../assets/images/ergonomic-2.png';
import ergonomic3 from '../../assets/images/ergonomic-3.png';
import ergonomic1 from '../../assets/images/ergonomic-1.jpg';
import principal from '../../assets/images/principal.png';

const AutoCarousel = autoPlay(SwipeableViews);

class Login extends Component {
  static propTypes = {
    signing: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      isSigning: PropTypes.bool,
    }).isRequired,
  };

  state = {
    swipeImages: [
      { imagePath: ergonomic2 },
      { imagePath: ergonomic3 },
      { imagePath: ergonomic1 },
    ],
    activeStep: 0,
    login: '',
    senha: '',
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { login, senha } = this.state;
    if (String(login).trim() && String(senha).trim()) {
      const { signing } = this.props;
      signing(login, senha);
    }
  }

  handleChangeSwipe = activeStep => this.setState({ activeStep })

  render() {
    const {
      login,
      senha,
      activeStep,
      swipeImages,
    } = this.state;
    const { classes, auth, theme } = this.props;

    if (auth.isLogged) {
      return <Redirect to={{ pathname: '/app' }} />;
    }

    return (
      <Grid container spacing={0} className={classes.container} direction="column" alignItems="stretch">
        <Grid container item spacing={0} className={classes.content} direction="row-reverse" justify="center" alignItems="center">
          <Grid container item xs={12} sm={12} md={4} lg={4} justify="center" alignItems="center">
            <Paper className={classes.paper} elevation={0}>
              <Typography className={classes.title} component="h1" variant="h2">
                Login
              </Typography>
              <Form onSubmit={this.handleSubmit} autoComplete="off" noValidate>
                <TextField
                  className={classes.textfield}
                  id="login-field"
                  label="Login"
                  value={login}
                  onChange={this.handleChange('login')}
                  margin="normal"
                  variant="outlined"
                  type="email"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className={classes.textfield}
                  id="senha-field"
                  label="Senha"
                  value={senha}
                  onChange={this.handleChange('senha')}
                  margin="normal"
                  variant="outlined"
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  type="submit"
                  disabled={auth.isSigning}
                >
                  {auth.isSigning ? (
                    <CircularProgress color="inherit" size={32} thickness={3} />
                  ) : (
                    'Entrar'
                  )}
                </Button>
              </Form>
              <Link
                component={RouterLink}
                className={classes.link}
                variant="body1"
                to="#"
                size="small"
              >
                Esqueci a senha
              </Link>
            </Paper>
          </Grid>
          
              <Grid container item xs={12} sm={12} md={8} lg={8} justify="center" alignItems="center">
              
                <AutoCarousel
                  className={classes.imageAutoCarousel}
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={activeStep}
                  onChangeIndex={this.handleChangeSwipe}
                  interval={3500}
                  enableMouseEvents
                  >
                  {swipeImages.map(image => (
                    <GridImage>
                    <SwipeImage key={image.imagePath} image={image.imagePath} />
                    </GridImage>
                    ))}
                </AutoCarousel>
                
              </Grid>
          
        </Grid>
        <Grid className={classes.footer} container item justify="flex-end" alignItems="center">
          <img className={classes.logo} src={principal} alt="logotipo" style={{height: 55}}/>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  auth: {
    isSigning: user.isSigning,
    isLogged: user.isLogged,
    hasError: user.error,
  },
});

const mapDispatchToProps = dispatch => ({
  signing: (login, senha) => dispatch(UsuarioActions.signing(login, senha)), 
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Material, { withTheme: true }),
)(Login);
