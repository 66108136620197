export const Material = theme => ({
  container: {
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    padding: '0vw 3vw',
  },
  subTitle: {
    borderBottom: "2px"
  },
  formControl: {
    marginBottom: '10px'
  },

  
  empresaNome: {
    //backgroundColor: '#d6d6d6', 
    padding: '5px',
    border: '1px solid black',
    borderRadius: 5, 
    marginBottom: '20px'
  },
  textfield: {
    margin: '0px 10px 10px 0px',
  }
})