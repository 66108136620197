import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SwipeImage = styled.div`
  flex: 1;
  height: 85vh;
  min-height: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  padding: 0;
`;

export const GridImage = styled.div`
  @media(max-width: 950px) {
    display:none;
  }
`;

export const Material = theme => ({
  container: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flex: 1,
    overflow: 'auto',
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignSelf: 'stretch',
  },
  link: {
    textAlign: 'right',
  },
  logo: {
    height: '100%',
  },
  textfield: {
    alignSelf: 'stretch',
  },
  paper: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    maxWidth: 460,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignSelf: 'center',
    
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 300,
  },
  footer: {
    backgroundColor: "#aaa",//theme.palette.primary.main
    padding: theme.spacing(2.5),
  },
  imageAutoCarousel: {
    flex: 1
  },
});
