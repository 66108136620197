import { isMobile } from 'react-device-detect';


const drawerWidth = 350;
export const Material = theme => ({
  paperRound: {
    overflow: 'hidden',
    backgroundColor: '#fff',
    color: '#666',
    border: '1px solid #d1d1d1',
    borderRadius: 5,
    marginTop: '10px',
    marginBottom: '10px',
    justifyContent: 'space-around',
  },
  filterButton: {
    position: "fixed",
    right: 0,
    top: 95,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#01ACF1",
    zIndex: 1,
  },
  titleFilter: {
    padding: theme.spacing(2),
    borderBottom: "solid 1px #eeeff8",
    fontWeight: 700,
    textTransform: "uppercase",
    color: "#fff",
    margin: 0,
  },
  filterClose: {
    backgroundColor: "#01ACF1",
    position: "fixed",
    right: 5,
    top: 5,
  },
  drawerFilter: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',

  },
  drawerFilterOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
  },
  drawerFilterClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7.1),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9.1),
    },
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
  },
  toolbarDashboard: {
    padding: theme.spacing(1),
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(2),
    '& .MuiFormLabel-root': {
      color: theme.palette.white,
    },
    '& input': {
      color: "#01ACF1",
    },
    '& button': {
      color: "#01ACF1",
    },
    '& label': {
      color: theme.palette.common.white,
    },
    '& label.Mui-focused': {
      color: theme.palette.common.white,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.common.white,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.common.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
  },
  formControlButton: {
    width: "100%",
  },
  btnlimparfiltro:{
    marginTop: theme.spacing(2),
    backgroundColor: "#f44336",
    '&:hover': {
      backgroundColor: "#aa2e25",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  fundodark: {
    backgroundColor: '#000000'
  },
  fundolight: {
    backgroundColor: '#FFFFFF'
  },
  tituloGraficodark: {
    color: "#00aeff",
    marginLeft: "10px",
    '& h3': {
      marginBottom: "2px",
    },
    '& hr': {
      color: "#228aba",
      borderWidth: "0.5px",
    }
  },
  tituloGraficolight: {
    color: "#000000",
    marginLeft: "10px",
    '& h5': {
      marginBottom: "1px",
      marginTop: "1px",
    },
    '& hr': {
      color: "#FFFFFF",
      borderWidth: "0.5px",
    }
  },
  titleDialog: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  tablePaper: {
    width: '100%',
  },
  tableCell : {
    position: "sticky",
    top: 0
    
  },
  tableContainer: {
    minHeight: isMobile ? "77vh" : "80vh",
    maxHeight: isMobile ? "77vh" : "80vh",
    '& .MuiInputLabel-animated': {
      color: "rgb(0, 0, 0, 0.87)",
      fontWeight: 500,
    }
  },
  cardPlanoAcao: {
    marginTop: 0,
    marginBottom: "5px",
    color: "#FFF",
  },
  titleCardPlanoAcao: {
    textTransform: "uppercase",
  },
  avatarPlanoAcao: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    width: "30px",
    maxHeight: "30px",
    textTransform: "uppercase",
    fontSize: "1em",
  },
  tituloPaginaGrafico: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2),
    color: "#fff",
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  legendaDor: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
      color: "#000"
    },
  },
  customTooltip: {
    backgroundColor: "#fff",
    padding: 5,
    borderRadius: 2,
  },
  legendaNasaIntensidade: {
    width: "100%",
    margin: "0 auto",
    marginTop: 10,
    marginBottom: 10,
    '& .MuiTableCell-root': {
      padding: 4,
      border: "solid 1px",
      fontSize: "1em",
      textTransform: "uppercase",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  legendaNasa: {
    width: "90%",
    margin: "0 auto",
    '& .MuiTableCell-root': {
      padding: 2,
      border: "solid 1px",
      fontSize: "1em"
    }
  },
  textFilter: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      color: "rgb(0, 0, 0, 0.87)",
      fontWeight: 500,
    }
  },
  tooltipTable: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
  container:{
    width: "100%",
    height: "calc(100vh - 98px)",
  },
  gridresumogerencial:{
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: "calc(100% - 60px)",
      padding: theme.spacing(0.5),
      '&:last-child': {
        marginBottom: 70,
      }
    },
    [theme.breakpoints.only('md')]: {
      height: "50%",
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      height: "calc(50% - 8px)",
      padding: theme.spacing(0),
    },
    '& .MuiPaper-root':{
      height: "100%",
    }
  },
  acoesMensais:{
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 70,
      width: "calc(100vw - 60px)",
      overflowX: "auto",
      whiteSpace: "nowrap",
      '& .MuiPaper-root':{
        height: "100%",
        minWidth: 700
      }
    },
  },
  graficoAcoesMensais:{
    height: "calc(50vh - 106px)",
    [theme.breakpoints.down('sm')]: {
      height: "calc(80vh - 106px)",
    },
  },
  gridsenso:{
    [theme.breakpoints.down('sm')]: {
      height: "90%",
      width: "calc(100vw - 60px)",
      overflowX: "auto",
      overflowY: "hidden",
      whiteSpace: "nowrap",
      padding: theme.spacing(0.5),
      '& .MuiPaper-root':{
        height: "100%",
        minWidth: 700
      }
    },
    [theme.breakpoints.only('md')]: {
      height: "50%",
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      height: "calc(44% - 8px)",
      padding: theme.spacing(0),
    },
    '& .MuiPaper-root':{
      height: "100%",
    }
  },
  gridsensoCorpinho:{
    [theme.breakpoints.down('sm')]: {
      height: "100%",
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.only('md')]: {
      height: "100%",
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      height: "calc(44% - 8px)",
      padding: theme.spacing(0),
    },
    '& .MuiPaper-root':{
      height: "100%",
    }
  },
  chipSenso:{
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 70,
    },
  },
  gridCargaMental:{
    height: "calc(45% - 5px)",
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      /*
      overflowY: "auto",
      height: "90%",
      marginBottom: theme.spacing(1),
    },
    '& .MuiPaper-root':{
      height: "100%",
    }
    */
      height: "90%",
      width: "calc(100vw - 60px)",
      overflowX: "auto",
      overflowY: "hidden",
      whiteSpace: "nowrap",
      padding: theme.spacing(0.5),
      '& .MuiPaper-root':{
        height: "100%",
        minWidth: 700
      }
    }
  },

  gridLegendaNasa:{
    width: "100%",
    [theme.breakpoints.up('md')]:{
      height: "calc(10% - 8px)",
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 70,
    },
  },
  chipsNasa: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
      color: "#000"
    },
  },
  chipNasa:{
    backgroundColor: "#bdbdbd",
    color: "#000",
    fontWeight: "bold",
  },
  nasaPouca:{
    backgroundColor: "#5588ff",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  nasaAlguma:{
    backgroundColor: "green",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  nasaModerada:{
    backgroundColor: "yellow",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  nasaBastante:{
    backgroundColor: "rgb(237, 0, 0)",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  nasaIntoleravel:{
    backgroundColor: "purple",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  graficoCargaMental:{
    height: "70%",
    [theme.breakpoints.down('sm')]: {
      height: "calc(45vh - 150px)",
      marginBottom: 70,
      marginTop: 10,
      minWidth: 600,
    },
  },
  gridAfastamentos:{
    height: "calc(50% - 12px)",
    [theme.breakpoints.down('sm')]: {
      height: "90%",
    },
    '& .MuiPaper-root':{
      height: "100%",
    }
  },
  gridDiasPerdidos:{
    height: "calc(50% - 12px)",
    [theme.breakpoints.down('sm')]: {
      height: "90%",
      marginBottom: 70,
      marginTop: 10,
    },
    '& .MuiPaper-root':{
      height: "100%",
    }
  },
  gridPep:{
    height: "calc(50% - 8px)",
    [theme.breakpoints.down('sm')]: {
      height: "90%",
    },
  },
  gridBlitz:{
    height: "calc(50% - 8px)",
    [theme.breakpoints.down('sm')]: {
      height: "90%",
      marginBottom: 70,
      marginTop: 10,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(3),
  },
  gridInfoHomeOffice: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  gridHomeOffice: {
    width: "100%",
    height: '23%',  
    [theme.breakpoints.down('sm')]: {
      height: "calc(100% - 40px)",
      padding: theme.spacing(0.5),
      '&:last-child': {
        marginBottom: 70,
      }
    },
    /*
    [theme.breakpoints.only('md')]: {
      height: "20%",
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      height: "20%",
      padding: theme.spacing(0),
    },
    */
    '& .MuiPaper-root':{
      height: "100%",
    }
  },
  titleHomeOffice: {
    height: "calc(100% - 85px)", 
    width: "100%", 
    display: 'flex', 
    flexDirection: 'row'
  },
  buttonArea: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'flex-end', 
    justifyContent: 'space-between', 
    width: '40%'
  },
  barraArea: {
    border: '2px solid black', 
    //marginTop: 5, 
    borderRadius: 4
  },
  barraInfo: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between',
  },
  acoesMensais1:{
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 70,
      width: "calc(100vw - 60px)",
      overflowX: "auto",
      whiteSpace: "nowrap",
      '& .MuiPaper-root':{
        height: "100%",
        minWidth: 700
      }
    },
  },
  graficoAcoesMensais1:{
    height: "calc(50vh - 306px)",
    [theme.breakpoints.down('sm')]: {
      height: "calc(80vh - 106px)",
    },
  },
  icon: {
    margin: 5,
    cursor: 'pointer !important',
  },
  paperBox: {
    borderRadius: '2px',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
  },
});
