import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  Grid,
  Typography
}from '@material-ui/core';

import color from '../../styles/theme'
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import api from '../../services/api';
import { Material } from './styles';
import NotificationActions from '../../store/ducks/notifier';
import { apiS3Alicrim } from '../../services/api';
import Home from './components/home';
import '../../assets/css/camera.css';
import './questionario.css'

class Questionario extends Component {
  state = {
    apiKey: '',
    img: '',
    homeState: {
      nome: '',
      email: '',
      matricula: '',
      cargo: null,
      unidade: null,
      ghe:null,
      turno:null,
      id: null,
      _id:"",
      dataNasa:{
        nasa: {
          fase1:null,
          fase2:null
        }
      }
    },
    loading: false,
    updateLista: false,
    send: false
  }

  loadImg = async() => {
		const { match } = this.props;
		this.setState({loadingImg: true})
		try{
			// const {data} = await apiS3.get(`/imagem/alicrim/${selected.id}`)
			// var binary = '';
			// var bytes = new Uint8Array( data );
			// var len = bytes.byteLength;
			// for (var i = 0; i < len; i++) {
			// 	binary += String.fromCharCode( bytes[ i ] );
			// }
			// const base = window.btoa( binary );
      const image = await apiS3Alicrim.get(`/maketing/background/get/${match.params.apiKey}`)
      console.log(image)
			this.setState({img: image.data})
			this.setState({loadingImg: false})
		}catch(err){
			console.log(err)
		}
	}

  async componentWillMount() {
    await this.loadImg();
  }

  componentDidUpdate(prevProps, prevState) {
    const { setStatus, status} = this.props;

    if (status && status.updateLista) {
      setStatus({ updateLista: !status.updateLista });
      this.setState({send: true})
    }
  }

  nextHome = (value) => {
    const {setFieldValue, match, handleSubmit} = this.props;
    console.log('value',value)
    this.setState({value})
    setFieldValue('name', value.nome);
    setFieldValue('company', value.empresaNome);
    setFieldValue('unity', value.unidade);
    setFieldValue('office', value.cargo);
    setFieldValue('email', value.email);
    setFieldValue('phone', value.telefone);
    setFieldValue('celphone', value.celular);
    setFieldValue('ncolaboradores', value.colaboradores);
    setFieldValue('service_id', value.serviceSelec.id)
    setFieldValue('serviceName', value.serviceSelec.description)
    setFieldValue('content_id', value.content)
    setFieldValue('apiKey', match.params.apiKey)
    handleSubmit();
  }

  render() {
      // const {
      //   classes
        
      // } = this.props;
      const {
        img, 
        send
      } = this.state;
    return (
      
      <Grid className={!send ? 'container' : 'containerinit'} style={{backgroundImage: `url("data:image/png;base64,${img}")`, backgroundRepeat: 'no-repeat', backgroundSize:'100%',
      // bottom: '0',
      // color: 'black',
      // left: '0',
      // overflow: 'auto',
      // padding: '3em',
      // position: 'absolute',
      // right: '0',
      // textAlign: 'center',
      // top: '0'
      }}>
          { !send ? 
          <div className="container-total">
            <div className="first" >
                <img className="image-firts" src={`data:image/png;base64,${img}`} alt="Minha Figura"/>
            </div>
            <div className="second"><Home style={{ display : 'none'}} next={value => this.nextHome(value)} /></div>
          </div>
            :
            <Grid style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: color.palette.success.main, color: "#fff"}}>
              <CheckCircleIcon fontSize="large" />
              <Typography>{"Obrigado pelo seu tempo!"}</Typography>
            </Grid>
          }

         

      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({  
  notify: (message, options) => dispatch(NotificationActions.notify(message, options)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(Material, { withTheme: true }),
  withFormik({
    displayName: 'Questionario',
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      name: null,
      company: null,
      unity: null,
      office: null,
      email: null,
      phone: null,
      celphone: null,
      ncolaboradores: 0,
      service_id: null,
      serviceName:null,
      content_id:null,
      apiKey: null
    }),
    handleSubmit: async (values, { props, setSubmitting, resetForm, setStatus }) => {
     
      const form = {
        ...values
      }
      try {
        console.log('form', form)
        await api.post('/marketing/saveLead', {...form});
        await setStatus({ updateLista: true });        
        resetForm();
      } catch (err) {
        props.notify(`Houve um problema ao salvar, ${err}`, { variant: 'error' });
        console.log(err);
      }
    },
  }),
)(Questionario);