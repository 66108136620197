import axios from 'axios';
import qs from 'qs';
import store from '../store';
import UsuarioActions from '../store/ducks/usuario';
import NotificationActions from '../store/ducks/notifier';

export const rootURL = process.env.REACT_APP_V2_API_URL;
export const rootCepURL = 'https://consultacep.v2saude.com.br/'; 
export const rootReport = process.env.REACT_APP_REPORT;
export const rootS3 = process.env.REACT_APP_S3;

/**
 * Função para atribuir o JWT nas requisições
 */
function accessTokenInterceptor(api) {
  api.interceptors.request.use((req) => {
    const config = req;
    const token = localStorage.getItem('@alicrim:accessToken');

    if (token) {
      config.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...config.headers,
      };
    }

    return config;
  });
}

/**
 * Service para acesso a API do Clin
 */
const api = axios.create({
  baseURL: rootURL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat', skipBlank: true }),
});
accessTokenInterceptor(api);
api.interceptors.response.use(res => res, (err) => {
  if (err && err.response && err.response.status === 401) {
    store.dispatch(NotificationActions.clear());
    store.dispatch(NotificationActions.notify('Sessão expirada'));
    store.dispatch(UsuarioActions.signout());
  }
  return Promise.reject(err);
});
export default api;

/**
 * Service para acesso a API do Consulta CEP
 */
const configConsultaCEP = axios.create({
  baseURL: rootCepURL,
});
accessTokenInterceptor(configConsultaCEP);
export const apiConsultaCEP = configConsultaCEP;

const configReport = axios.create({
  baseURL: rootReport,
});
export const apiReport = configReport;


const configS3Alicrim = axios.create({
  baseURL: rootS3,
});
export const apiS3Alicrim = configS3Alicrim;

/**
 * Service para acesso a API do S3
 */
const authS3 = {
  username: 'apikey',
  password: 'cZ2uxsGsQHeRU2j4Ny2hRUsUPFswWsjs',
};

const configS3 = axios.create({
  baseURL: `https://s3.v2saude.com.br`,
  responseType: 'arraybuffer',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
  auth: authS3,
  //transformRequest: [data => qs.stringify(data)],
});
export const apiS3 = configS3;