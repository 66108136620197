import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import NotificationActions from '../../../../store/ducks/notifier';
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Grid,
  // FormLabel,
  CircularProgress
}from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import api from '../../../../services/api'
import { Material } from './styles';
import './home.css'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        // mask={['(', /[0-9]/, /[0-9]/, ')', ' ' , /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        placeholderChar={'\u2000'}
      />
    );
}

function TextMaskTelCustom(props) {
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        // mask={['(', /[0-9]/, /[0-9]/, ')', ' ' , /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        placeholderChar={'\u2000'}
      />
    );
}


TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskTelCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Home extends Component {
  state = {
    falhaApiKey: false,
    lang: this.props.lang,
    listService: [],
    inputService: '',
    service:null,
    serviceSelec: '',
    nomeUser: ''
  }

  async componentWillMount() {
    this.fetchService();
  }

  componentDidUpdate(prevProps, prevState) {
    const { inputService, service } = this.state;

    if (inputService === '' && inputService !== prevState.inputService) {
      this.setState({listService: service});
    }else if(inputService !== prevState.inputService) {
      this.filterService()
    }
  }


  filterService = () => {
    const { service, inputService } = this.state
    const rawList = service.filter(item => {
      const valorMinusculo = inputService.toLowerCase()
      const cidadeMinusculo = item.descricao.toLowerCase()
      if(cidadeMinusculo.includes(valorMinusculo)){
        return item
      }
      return null;
    })
    this.setState({listService: rawList})
  }

  fetchService = async() => {
    try{
      const {data} = await api.get(`/marketing/services/`)
      // console.log('services',data)
      this.setState({service: data})
      this.setState({listService:data})
    }catch(err){
      console.log(err)
    }
  }

  handleSubmit = async(event) => {
    const {newPasswordAgain,
      newPassword
    } = this.state;
    const {notify} = this.props;

    if(newPasswordAgain === null || newPassword == null){
      notify("Preencha todos os campos!", { variant: 'error' });
      return
    }

    if(newPasswordAgain !== newPassword){
      notify("Os campos estão com senhas diferentes!", { variant: 'error' });
      return
    }


   
    return this.props.next({newPasswordAgain, 
            newPassword
      })
  }


  changeService = value => {
    // console.log('changeService', value.target)
    this.setState({inputService: value.target.value})
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  isEmail(email){

    let usuario = email.substring(0, email.indexOf("@"));
    let dominio = email.substring(email.indexOf("@")+ 1, email.length);

    if ((usuario.length >=1) &&
        (dominio.length >=3) &&
        (usuario.search("@")===-1) &&
        (dominio.search("@")===-1) &&
        (usuario.search(" ")===-1) &&
        (dominio.search(" ")===-1) &&
        (dominio.search(".")!==-1) &&
        (dominio.indexOf(".") >=1)&&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
        return true;
    } else {
        return false
    }

  }

  

  render() {
      const {
        falhaApiKey,
        newPassword,
        newPasswordAgain
      } = this.state;
      const {
        classes,
        user
      } = this.props;
    return (
      <Grid className="content-quiz-new">
        <div className="container-content-new">
          <div className="container-squad-new"> 
            <div className="title-squad-new">
              <span>
                 OLÁ, 
                 <span class="name-span-new">{user !== null && user !== '' && user !== undefined && user.nome}!</span>
                 <br/>PARA ALTERAR SUA SENHA BASTA PREENCHER OS CAMPOS COM A NOVA SENHA
              </span>
            </div>
            <Grid item className="content-grid-new">
              <TextField
                label={"Nova Senha *"}
                className={classes.textfield}
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={value => this.setState({newPassword: value.target.value})}
                variant="outlined"
                type="password"
                fullWidth
                size="small"
              /> 
              <TextField
                label={"Repetir a Senha *"}
                className={classes.textfield}
                name="newPasswordAgain"
                id="newPasswordAgain"
                value={newPasswordAgain}
                onChange={value => this.setState({newPasswordAgain: value.target.value})}
                variant="outlined"
                type="password"
                fullWidth
                size="small"
              /> 
              {/* <div className ="content-btn"> */}
                <div className="btn-down-new">
                  <Button
                    className="down-new"
                    fullWidth
                    disabled={falhaApiKey}
                    style={{color: '#fff'}}
                    onClick={() => this.handleSubmit()}
                  >
                    {falhaApiKey?
                      <CircularProgress size={25} /> : "Alterar Senha"
                    }
                  </Button>
                
                  {/* <span className="span-btn">
                    Enviaremos o conteúdo par e-mail
                  </span> */}
                </div>
              {/* </div> */}
            </Grid>
          </div>
        </div>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  notify: (message, options) => dispatch(NotificationActions.notify(message, options)),
});

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(Material, { withTheme: true }),
)(Home);

