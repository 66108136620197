import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setMenu: ['isMenuOpen'],
  setToolbar: ['isToolbarOpen'],
  setToolbarView: ['isToolbarView'],

  setPreferences: ['preferences'],


  signing: ['login', 'senha'],
  signingSuccess: ['user'],
  signingFailure: ['error'],
  signout: null,

  fetchUsuarios: null,
  fetchUsuariosSuccess: ['usuarios'],
  setUsuario: ['usuario', 'index'], 
}, { prefix: 'user/' });

export default Creators;
export const UsuarioTypes = Types;

const INITIAL_STATE = Immutable({
  preferences: {
    periodo: 'MES',
    dataInicial: "",
    dataFinal: "",
    setoresSelecionados: [],
    cargosSelecionados: [],
    grupoCliente: false,
    lang: 'pt_BR'
  },
  isToolbarView: true,
  isToolbarOpen: false,
  isMenuOpen: false,
  info: [],
  permissoes: [],
  isSigning: false,
  isLogged: false,
  error: false,
  message: '',
  usuarios: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PREFERENCES]: (state, { preferences }) => {
    return Immutable.merge(state, { preferences });
  },

  [Types.SET_TOOLBAR]: (state, { isToolbarOpen }) => {
    return Immutable.merge(state, { isToolbarOpen });
  },

  [Types.SET_TOOLBAR_VIEW]: (state, { isToolbarView }) => {
    return Immutable.merge(state, { isToolbarView });
  },

  [Types.SET_MENU]: (state, { isMenuOpen }) => {
    return Immutable.merge(state, { isMenuOpen });
  },

  [Types.SIGNING]: state => Immutable.merge(state, { isSigning: true }),

  [Types.SIGNING_SUCCESS]: state => Immutable.merge(state, {
    isSigning: false, isLogged: true, error: false, message: '',
  }),

  [Types.SIGNING_FAILURE]: (state, { error }) => Immutable
    .merge(state, { isSigning: false, error: true, message: error }),

  [Types.SIGNOUT]: (state) => {
    return Immutable.merge(state, INITIAL_STATE);
  },

  [Types.FETCH_USUARIOS_SUCCESS]: (state, { usuarios }) => Immutable
    .merge(state, { usuarios }),
  [Types.SET_USUARIO]: (state, { usuario, index }) => {
    const usuarios = Immutable.asMutable(state.usuarios, { deep: true });

    if ((index !== null || index !== undefined) && typeof index === 'number') {
      usuarios[index] = usuario;
      return Immutable.merge(state, { usuarios });
    }

    if (usuario && usuario.id) {
      const usuarioIndex = usuarios.findIndex(({ id }) => id === usuario.id);

      if (usuarioIndex !== -1) {
        usuarios[usuarioIndex] = usuario;
      } else {
        usuarios.push(usuario);
      }
    }

    return Immutable.merge(state, { usuarios });
  },
});
