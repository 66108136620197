import api from '../api';

export default class UsuarioService {
  static async login(username, password) {
    const { data: { usuario, accessToken } } = await api.post('/auth/login', { username, password });

    return { usuario, accessToken };
  }

  static async logout() {
    const { data } = await api.post('/auth'); 
    
    return { data };
  }

  static async whoami() {
    const { data } = await api.get('/whoami');

    return data;
  }

  static async getAll() {
    const { data } = await api.get('/v2-api/usuario/');

    return data;
  }

  static async getById(userId) {
    const { data } = await api.get(`/v2-api/usuario/${userId}`);

    return data;
  }

  static async search(
    ativo = true, chave = undefined,
  ) {
    const { data } = await api.get('/v2-api/usuario/', {
      params: {
        ativo, chave,
      },
    });

    return data;
  }

  static async save(usuario) {
    const { data } = await api.post('/v2-api/usuario/', usuario);

    return data;
  }

  static async atualizarStatus(id) {
    const { data } = await api.patch(`/v2-api/usuario/status/${id}`);

    return data;
  }
}
