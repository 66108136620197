import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Material } from '../../../styles';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Table,
    Grid,
    TextField,
    TablePagination,
    ButtonGroup,
    Button,
    Modal,
    // Typography,
    // Typography,
	// Button,
	// Modal
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@material-ui/icons/Close';
// import Tooltip from '../TooltipCustom'
// import { format, addDays } from 'date-fns'
import moment from 'moment'
import excel from '../../../../../../assets/images/excel.png'
import pdf from '../../../../../../assets/images/pdf.png'
import DashboardService from '../../../../../../services/Dashboard';
import { saveAs } from 'file-saver';
import Loader from 'react-loader-spinner';
import { lb_modal, lb_tabelas } from '../../../../../../libs/labels';
import { lb_modalEn, lb_tabelasEn } from '../../../../../../libs/labelsEn';
import { lb_modalEs, lb_tabelasEs } from '../../../../../../libs/labelsEs';
import './modal-pesquisa-interno.css'
import api from '../../../../../../services/api'
import ModalNasa from '../../../../../ModalNasa'

var time = null;

class ModalPesquisaInterno extends Component {
    state = {
        filterTable: ['','','','','','','',''],
        loadExport:false,
        rowsPerPage: 50,
        page: 0,
        data: {},
        resultados: [],
        lb: {},
        lb_tabelas: {},
        columns:[],
        isOpen:false,
        modalPatern: false,
        modalDelete: false,
        row:{}
    }

    componentWillMount = () => {

        // this.fetch();
        const preferences = JSON.parse(localStorage.getItem('@alicrim:dashboard'));
        if(preferences === null || preferences === undefined || preferences === '') {
            this.setState({lb: lb_modal, lb_tabelas: lb_tabelas})
        } else if(preferences.lang === 'pt_BR'){
			this.setState({lb: lb_modal, lb_tabelas: lb_tabelas})
		}else if(preferences.lang === 'en'){
			this.setState({lb: lb_modalEn, lb_tabelas: lb_tabelasEn})
		}else if(preferences.lang === 'es'){
			this.setState({lb: lb_modalEs, lb_tabelas: lb_tabelasEs})
        }
        const searchColumns = [
            { id: 'data', label: lb_tabelas["data"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            // { id: 'ghe', label: lb_tabelas["gerg"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            { id: 'setor', label: lb_tabelas["setor"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            { id: 'locaisDeTrabalho', label: lb_tabelas["localTrabalho"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            { id: 'cargos', label: lb_tabelas["cargo"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            // { id: 'unidade', label: lb_tabelas["unidade"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            { id: 'turno', label: lb_tabelas["turno"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            { id: 'cargaMental', label: lb_tabelas["cargaMental"], tipo: 'TEXTO', filter: true, minWidth: 170 },
            { id: 'action', label: '', tipo: 'TEXTO', filter: false, minWidth: 170 }
          ];
         
        this.setState({columns: searchColumns})
        this.fetch();
        // console.log(this.state.data)
    }
    
    // componentDidUpdate = () => {
    //     // const { modalPatern} = this.props;
    //     const { isOpen } = this.state;

    //     // console.log('resposta',resposta)
    //     // console.log('pergunta',pergunta)

    //     // console.log(modalPatern)
    //     // console.log(isOpen)
    //     if(!isOpen) {
    //         this.setState({isOpen: true})
    //         this.fetch();
    //     }

    //     if(isOpen) {
    //         this.setState({isOpen: false})
    //     }
        
        
    //     // console.log(this.state.data)
	// }


    timeToLoad = (reload) => {
		clearTimeout(time)
		if(reload){
		  time = setTimeout(() => {
			this.setState({loadExport: false})
		  }, 60000)
		}
    }
    
    exportToXls = async()=> {
		// this.setState({loadExport: true})
        // const filter = this.getFilter();

        // const preferences = JSON.parse(localStorage.getItem('@alicrim:dashboard'))
	
		// let filter = {};
	
		// filter.periodo = preferences.periodo || 'MES';
		// filter.cliente = preferences.clienteSelecionado ? preferences.clienteSelecionado.codigo : undefined;
		
		// filter.dataInicial = preferences.dataInicial;
        // filter.dataFinal = preferences.dataFinal;
        // console.log(process.env.REACT_APP_REPORT)
		// window.location=`${process.env.REACT_APP_REPORT}homeoffice/xls/${filter.cliente}/${filter.dataInicial}/${filter.dataFinal}/${filter.periodo}`;
		// this.timeToLoad(1);
        //await apiReport.get(`/homeoffice/xls/${cliente}/${selected.id}`);
        
        const {satisfactory, period} = this.props;
        // console.log(satisfactory)
        // console.log(period)
        // const {page, rowsPerPage, data} = this.state;
		// let offset = page*rowsPerPage;
        // this.setState({loading: true})
        this.setState({loadExport: true})

		// let pageValid = page;
		// while(offset > data.totalResultados && pageValid!==0){
		// 	pageValid=pageValid-1;
		// 	offset = pageValid*rowsPerPage 
		// }
		// this.setState({page: pageValid})

		try{
            var result={};
            const periodClean = period.replace('/', ',').replace('/', ',');
            result = await DashboardService.getDetalheOverviewReport({satisfactory, periodClean})
            // console.log('result', result)
            const arrayBuffer = this.base64ToArrayBuffer(result);
            this.createAndDownloadBlobFile(arrayBuffer, 'homeoffice');
            // this.setState({loading: false})
            this.setState({loadExport: false})
		}catch(err){
            this.setState({loadExport: false})
			console.log(err)
		}
    }

    base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    createAndDownloadBlobFile(body, filename, extension = 'xlsx') {
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
    
    getFilter = async () => {
		const preferences = JSON.parse(localStorage.getItem('@alicrim:dashboard'))
	
		let filter = {};
	
		filter.periodo = preferences.periodo || 'MES';
		filter.cliente = preferences.clienteSelecionado ? preferences.clienteSelecionado.codigo : undefined;
		
		filter.dataInicial = preferences.dataInicial;
        filter.dataFinal = preferences.dataFinal;
        // console.log(filter)
		return filter;
	}

    exportTo = (ext) => async () => {
        // const {  columns  } = this.props;
        const { resultados, lb, columns } = this.state;

        let title = lb["modalPesquisa.titleNasa"];

        const MIME = {
            xls: 'application/vnd.ms-excel',
            pdf: 'application/pdf',
        }
        // console.log(data)
        var rawData = resultados.map(item => {
            // item.data = format(new Date(addDays(new Date(item.data), 1)), 'yyyy-MM-dd')
            return item
        })
        try {
            const form = {
                header: columns.map(({ label:descricao,  tipo }) => ({ descricao, tipo })),
                cols: rawData.map(item => columns.map(({ id }) => id).map(key => item[key] || '')),
            }
            let blob = await DashboardService.exportTo(title, ext, form);
            blob = new Blob([blob], { type: MIME[ext] });
            saveAs(blob, `${title}.${ext}`);
        } catch(err) {
            console.log(err)
        }
    }

    handleCloseModal = async() => {
        this.setState({modalPatern:false})
        await this.fetch();
    }
    handleCloseModalDelete = () => {
        this.setState({modalDelete:false})
    }

    fetch = async() => {
        const { match, lang } = this.props;
        const {page, rowsPerPage, data} = this.state;
		let offset = page*rowsPerPage;
        this.setState({loadExport: true})
       
        // console.log('resposta',resposta)
        // console.log('pergunta',pergunta)

		let pageValid = page;
		while(offset > data.totalResultados && pageValid!==0){
			pageValid=pageValid-1;
			offset = pageValid*rowsPerPage 
		}
		this.setState({page: pageValid})

		try{
            var result={}; 

            const {data} = await api.get(`/cliente/${match.params.apiKey}`, {params: {lang}});
            // const empresa = {nome: data.nomeOuRazao, cod: data.codigo, logo: data.logo};
            // await this.setState({empresa});

            // console.log('empresa',empresa)
            var cliente = data.codigo;
            // console.log('cliente', cliente)

            result = await DashboardService.getDetalheMentalChargeSummaryAll({offset, rowsPerPage, cliente})

            console.log('result', result)
            const dataDefault = {
                totalResultados:0
            }
            await this.setState({data:  (result.error === true ? dataDefault : result.pesquisaDto), resultados:  (result.error === true ? [] : result.pesquisaDto.resultados)})
            // console.log('resultado', result.pesquisaDto.resultados)
            this.setState({loadExport: false})
		}catch(err){
            this.setState({loadExport: false})
			console.log(err)
		}
	}

    filtrarTabela = event => {
		const {filterTable} = this.state;
		const value = event.target.value;
		const pos = event.target.name;
		// console.log(pos)
		var newFilter = filterTable;
		newFilter[pos] = value
		this.setState({filterTable:newFilter})
    }


    edit = (row) => {
       console.log('edit')
       this.setState({modalPatern:true})
       this.setState({row:row})
    }

    deleteRow = (row) => {
        console.log('delete',row)
        this.setState({modalDelete:true})
        this.setState({row:row})
     }

     nao = () => {
        this.setState({modalDelete:false})
     }

     sim = async() => {
        await this.deleteModel();
        await this.fetch();
        this.setState({modalDelete:false}) 
     }

     deleteModel = async() => {
        const { row } = this.state
        let deleted = {
            _id: row.id
        }
        try{
          const {data} = await api.post('/quiz/delete',  deleted);
          console.log(data)
        }catch(err){
          console.log(err)
        }
      }
    

    modalDetail = () => {
        const {classes} = this.props;
        const {modalPatern, row} = this.state;
        return (
            <div>
                { modalPatern &&  <ModalNasa classes={classes} row={row} modalPatern={modalPatern} handleCloseModal={this.handleCloseModal}></ModalNasa> }
            </div>
        )
    }

    filter = (content, list) => {
        const {filterTable} = this.state
        const preferences = JSON.parse(localStorage.getItem('@alicrim:dashboard')) || 'pt_BR'
        const formatDataMoment = preferences.lang ==='pt_BR'? 'DD/MM/YYYY' : 'YYYY/MM/DD'
		var teste = true
		var rawContent = content

		list.map((item, index) => {
			var value = ""
			const sliceProp = item.split('.');
			if (sliceProp.length > 1) {
				sliceProp.forEach(p => {
						if (rawContent[p])
						rawContent = rawContent[p];
						else
						rawContent = "";
				});
				value = rawContent.replace(".", ",");
			}else {
				if(item === 'data'){
					value = moment(content[item]).format(formatDataMoment)
				}else{
					value = content[item];
				}
			}
			if(!(new RegExp(`${filterTable[index]}`,'ig').test(value))){
				teste = false
            }
            
            return item;
		})
		return teste
    }
    
    renderLoading = () => {
		return (
			<div
				style={{
                marginTop: "22%",
				width: "100%",
				height: "100",
				display: "flex",
				justifyContent: "center",
                alignItems: "center",
				}}
			>
				<Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
			</div>
		)
    }
    
    getCorResultado = (media) => {
        if(media < 20) {
            return "#5588FF";
        } else if (media < 40) {
            return "green";
        } else if (media < 60) {
            return "yellow";
        } else if (media < 80) {
            return "rgb(237, 0, 0)";
        } else {
            return "purple";
        }
    }

    render() {
        // eslint-disable-next-line
        const { classes, } = this.props;
        const {data, page, rowsPerPage, lb, resultados, columns, modalDelete} = this.state;
        // const preferences = JSON.parse(localStorage.getItem('@alicrim:dashboard')) || 'pt_BR'
        // const formatData = preferences.lang ==='pt_BR'? 'DD/MM/YYYY' : 'YYYY/MM/DD'
        return (
            // <Modal >
				<Grid container className='container-modal'>
                    {/* <Grid container justify='space-between' alignItems='center' style={{width: '100%', height: '5%'}}>
                        <Grid></Grid>
                        <Typography style={{color: '#444', fontSize: '3vh', fontWeight: 'bold'}}>{type === 0 ? lb["modalPesquisa.titleCorlett"]:lb["modalPesquisa.titleNasa"]}</Typography>
                        <Button onClick={handleCloseModal}><CloseIcon/></Button>
                    </Grid> */}
                    <Paper className={classes.tablePaper} style={{height: '93.5vh'}}>
                        <TableContainer className="container-table">
                            <Table stickyHeader size="small" className="overview-table" aria-label="a dense table" style={{tableLayout: 'auto'}}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column, index) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                filter={column.filter}
                                                style={{ minWidth: column.minWidth}}
                                            >
                                                
                                                <TextField
                                                    //id={index}
                                                    className='header-table'
                                                    name={index}
                                                    label={column.label}
                                                    type="text"
                                                    onChange={this.filtrarTabela}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        // style: {fontWeight: 'bold', color: '#000', fontSize: '0.95vw'}
                                                    }}
                                                />
                                            
                                            </TableCell> 
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resultados.filter(i =>
                                        this.filter(i, ['data', 'setor', 'locaisDeTrabalho', 'cargos', 'unidade', 'turno', 'cargaMental']))
                                        .map(row => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={JSON.stringify(row.id)} >
                                                    <TableCell>
                                                        <div className="teste-container">
                                                            <div className="position-left">
                                                                <div className="teste">
                                                                {columns.filter(c => c.id === 'data')[0].label}
                                                                </div>
                                                            </div>
                                                            <div className="position-right">
                                                                {row.data}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                     {/* <TableCell>
                                                        <div className="teste-container">
                                                            <div className="position-left">
                                                                <div className="teste">
                                                                {columns.filter(c => c.id === 'ghe')[0].label}
                                                                </div>
                                                            </div>
                                                            <div className="position-right">
                                                                {row.ghe}
                                                            </div>
                                                        </div>
                                                    </TableCell> */}
                                                   <TableCell>
                                                        <div className="teste-container">
                                                            <div className="position-left">
                                                                <div className="teste">
                                                                {columns.filter(c => c.id === 'setor')[0].label}
                                                                </div>
                                                            </div>
                                                            <div className="position-right">
                                                                {row.setor}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="teste-container">
                                                            <div className="position-left">
                                                                <div className="teste">
                                                                {columns.filter(c => c.id === 'locaisDeTrabalho')[0].label}
                                                                </div>
                                                            </div>
                                                            <div className="position-right">
                                                                {row.locaisDeTrabalho}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="teste-container">
                                                            <div className="position-left">
                                                                <div className="teste">
                                                                {columns.filter(c => c.id === 'cargos')[0].label}
                                                                </div>
                                                            </div>
                                                            <div className="position-right">
                                                                {row.cargos}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        <div className="teste-container">
                                                            <div className="position-left">
                                                                <div className="teste">
                                                                {columns.filter(c => c.id === 'unidade')[0].label}
                                                                </div>
                                                            </div>
                                                            <div className="position-right">
                                                                {row.unidade}
                                                            </div>
                                                        </div>
                                                    </TableCell> */}
                                                    <TableCell>
                                                        <div className="teste-container">
                                                            <div className="position-left">
                                                                <div className="teste">
                                                                {columns.filter(c => c.id === 'turno')[0].label}
                                                                </div>
                                                            </div>
                                                            <div className="position-right">
                                                                {row.turno}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell  align="left" style={{color: this.getCorResultado(row.cargaMental) , textShadow: this.getCorResultado(row.cargaMental) === 'yellow'? '1px 1px 1px #333':null}}>
                                                            <div className="teste-container">
                                                                <div className="position-left">
                                                                    <div className="teste">
                                                                    {columns.filter(c => c.id === 'cargaMental')[0].label}
                                                                    </div>
                                                                </div>
                                                                <div className="position-right">
                                                                    {row.cargaMental}
                                                                </div>
                                                            </div>
                                                    </TableCell>
                                                    <TableCell  align="left" style={{color: this.getCorResultado(row.cargaMental) , textShadow: this.getCorResultado(row.cargaMental) === 'yellow'? '1px 1px 1px #333':null}}>
                                                            <div className="teste-container">
                                                                <div className="position-left">
                                                                    <div className="teste">
                                                                    </div>
                                                                </div>
                                                                <div className="position-right">
                                                                    <ButtonGroup variant="contained" aria-label="contained primary button group">
                                                                        <Button className="editar" onClick={() => this.edit(row)}>EDITAR</Button>
                                                                        <Button className="excluir" onClick={() => this.deleteRow(row)} >EXCLUIR</Button>
                                                                    </ButtonGroup>
                                                                </div>
                                                            </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                    rowsPerPageOptions={[50, 250, 500, 1000]}
                                    labelRowsPerPage={`${lb["modalPesquisa.itensPorPag"]}: `}
                                    labelDisplayedRows={({ from, to, count }) => (`${(page+1)|| 0} de ${(
                                        (data.totalResultados%rowsPerPage) === 0 
                                        ? Math.floor((data.totalResultados/rowsPerPage)) 
                                        : (Math.floor((data.totalResultados/rowsPerPage)) + 1))  || 0} ( ${data.totalResultados === null || data.totalResultados === '' || data.totalResultados === undefined ? 0 : data.totalResultados } )`)}
                                    component="div"
                                    count={data.totalResultados}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={(event, value) => this.setState({page: value},() => this.fetch())}
                                    onChangeRowsPerPage={value => this.setState({rowsPerPage: value.target.value},() => this.fetch())}
                                />
                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {/* eslint-disable-next-line*/}
                                <img src={excel} className={classes.icon} onClick={this.exportTo('xls')}/>
                                {/* eslint-disable-next-line*/}
                                <img src={pdf} className={classes.icon} onClick={this.exportTo('pdf')}/>
                            </Grid>
                        </TableContainer>
                        {this.state.loadExport ? 
                        
                        <div className='loading'>
                        { this.renderLoading() }
                        </div>
                        : null}
                    </Paper>
                    <Grid item style={{overflowY: 'scroll', flexGrow: 1, width: '100%', display: 'flex'}}>
                        {this.modalDetail()}
                    </Grid>     

                   { modalDelete && 
                        <Grid className="centralizar" >
                            <Modal open={modalDelete} className="centralizar" >
                                <Grid  style={{backgroundColor: '#fff', height: '17vh', width:'50vh', outline: 'none'}}>
                                    <Grid container justify='space-between' alignItems='center' style={{marginLeft:'11px', width: '100%', height: '5%'}}>
                                        <Grid></Grid>
                                        <Button onClick={this.handleCloseModalDelete}><CloseIcon/></Button>
                                    </Grid>

                                    <Paper className={classes.tablePaper} style={{backgroundColor: '#fff', height: '17vh', width:'50vh', outline: 'none'}}>
                                        <div className="container-apagar">
                                            <div className="container-desejo">
                                                <span className="desejo">Deseja realmente apagar!</span>
                                            </div>
                                            <div className="container-button">
                                                {/* <ButtonGroup > */}
                                                    <Button className="nao" onClick={() => this.nao(1)}>NÃO</Button>
                                                    <Button className="sim" onClick={() => this.sim(1)} >SIM</Button>
                                                {/* </ButtonGroup> */}
                                            </div>
                                        </div>
                                    </Paper>

                                </Grid>
                            </Modal>  
                        </Grid>   }
                    
                </Grid>
			 /* </Modal> */
        );
    }
}
export default (withStyles(Material)(ModalPesquisaInterno));