import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import NotificationActions from '../../../../store/ducks/notifier';
import MaskedInput from 'react-text-mask'
// import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  CircularProgress,
  Modal
}from '@material-ui/core';
import NumberFormat from 'react-number-format';
// import { withStyles } from 'material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
// import color from '../../../../styles/theme'
import api from '../../../../services/api'
import { Material } from './styles';
import eua from '../../../../assets/images/eua-flag.png';
import brazil from '../../../../assets/images/brazil-flag.png';
import esa from '../../../../assets/images/es-flag.png';
// import sicredi from '../../../../assets/images/logoEmpresas/logo-sicredi.svg';
import { lb_questionario } from '../../../../libs/labels';
import { lb_questionarioEn } from '../../../../libs/labelsEn';
import { lb_questionarioEs } from '../../../../libs/labelsEs';
import './home.css'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
    />
  );
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      // thousandSeparator
      // isNumericString
    />
  );
}


TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Home extends Component {
  state = {
    falhaApiKey: false,
    empresa: {},
    cargo: [],
    unidade: [],
    nome: '',
    email: '',
    matricula: '',
    cargoSelec: null,
    unidadeSelec: null,
    lang: this.props.lang,
    lb: {},
    modalTermo: false,
    inputCargo: null,
    listCargos: [],
    inputUnid: null,
    listUnid: [],
    age:null,
    officeDescription: '',
    unitDescription: ''
  }

  async componentWillMount() {
    const { lang } = this.props;
    this.fetch(lang)
  }

  fetch = (lang) => {
    if(lang === 'pt_BR'){
      this.setState({lb: lb_questionario})
    }else if(lang === 'en'){
      this.setState({lb: lb_questionarioEn})
    }else if(lang === 'es'){
      this.setState({lb: lb_questionarioEs})
    }
    this.fetchEmpresa();
    this.detectCam();
  }

  componentDidUpdate(prevProps, prevState) {
    const { lang } = this.props;
    const { inputCargo, inputUnid, cargo, unidade } = this.state;

    if(prevProps.lang !== lang && lang !== ''){
      if(lang === 'pt_BR'){
        this.setState({lb: lb_questionario})
      }else if(lang === 'en'){
        this.setState({lb: lb_questionarioEn})
      }else if(lang === 'es'){
        this.setState({lb: lb_questionarioEs})
      }
      this.fetchEmpresa();
    }

    if (inputCargo === '' && inputCargo !== prevState.inputCargo) {
      this.setState({listCargos: cargo});
    }else if(inputCargo !== prevState.inputCargo) {
      this.filterCargos()
    }

    if (inputUnid === '' && inputUnid !== prevState.inputUnid) {
      this.setState({listUnid: unidade});
    }else if(inputUnid !== prevState.inputUnid) {
      this.filterUnidade()
    }
  }

  filterCargos = () => {
    const { cargo, inputCargo } = this.state
    const rawList = cargo.filter(item => {
      const valorMinusculo = inputCargo.toLowerCase()
      const cidadeMinusculo = item.descricao.toLowerCase()
      if(cidadeMinusculo.includes(valorMinusculo)){
        return item
      }
      return null;
    })
      this.setState({listCargos: rawList}) 
  }

  filterUnidade = () => {
    const { unidade, inputUnid } = this.state
    const rawList = unidade.filter(item => {
      const valorMinusculo = inputUnid.toLowerCase()
      const cidadeMinusculo = item.descricao.toLowerCase()
      if(cidadeMinusculo.includes(valorMinusculo)){
        return item
      }
      return null;
    })
    this.setState({listUnid: rawList})
  }

  
  
  fetchEmpresa = async() => {
    const { match, lang } = this.props;

    this.setState({falhaApiKey: true})
    try{
      const {data} = await api.get(`/cliente/${match.params.apiKey}`, {params: {lang}});
      console.log('data-empresa', data)
      const empresa = {nome: data.nomeOuRazao, cod: data.codigo, logo: data.logo, grupoClienteId: data.grupoCliente === null ? null : data.grupoCliente.id };
      await this.setState({empresa});
      this.fetchCargo();
      this.fetchUnidade();
      this.setState({falhaApiKey: false})
    }catch(err){
      console.log(err)
      this.setState({falhaApiKey: true});
    }
  }

  fetchCargo = async() => {
    const { lang } = this.props;
    const {empresa} = this.state;
    try{
      const {data} = await api.get(`/controller/cargo/?cliente=${empresa.cod}`, {params:{lang}})
      this.setState({cargo: data})
      this.setState({listCargos: data}) 
      // console.log(data)
    }catch(err){
      console.log(err)
    }
  }

  cpfExists = async(cpf) => {
    const {lb} = this.state;
    const { lang, notify } = this.props;
    try{
      const {data} = await api.get(`/homequestionario/cpf/${cpf}/lang/${lang}`)
      // console.log(data);
      if(data.error) {
        notify(data.message, { variant: 'error' });
        return true;
      } else {
        return false;
      }
    }catch(err){
      console.log(err)
      notify(lb["notify.cpf"], { variant: 'error' });
      return true;
    }
  }

  fetchUnidade = async() => {
    const { lang } = this.props;
    const {empresa} = this.state;
    try{
      const {data} = await api.get(`/controller/unidade/?cliente=${empresa.cod}`, {params:{lang}})
      console.log('unidade', data)
      this.setState({unidade: data})
      this.setState({listUnid:data})
    }catch(err){
      console.log(err)
    }
  }

  detectCam = () => {
    const {notify, lang} = this.props;
    const lbNotify = lang === 'pt_BR'? lb_questionario : lang === 'en' ? lb_questionarioEn : lb_questionarioEs
    notify(lbNotify["notify.alertaCam"], { variant: 'error' });
    if(navigator && navigator.mediaDevices)  {
      navigator.mediaDevices.getUserMedia({video: true, audio: false})
        .then(function(stream) {
          stream.getTracks().forEach(track => track.stop());
        }).catch(function(err) {
          console.log("error: " + err);
          notify(lbNotify["notify.semCam"], { variant: 'error' });
        });
    }
  }  

  handleSubmit = async(event) => {
    const {cargoSelec, unidadeSelec, nome, email, matricula, lb, empresa, age} = this.state;
    const {notify} = this.props;
    console.log('cargoSelec', cargoSelec)
    // console.log('unidadeSelec', unidadeSelec)
    let grupoClienteId = empresa.grupoClienteId;

    if((grupoClienteId !== 26 && cargoSelec === null) || unidadeSelec === null || (grupoClienteId !== null && grupoClienteId === 1 && age === null) ){
      notify(lb["notify.erroSubmit"], { variant: 'error' });
      return
    }
    if((grupoClienteId !== 26  && nome.trim() === "") || email.trim() === "" || matricula.trim() === ""){
      notify(lb["notify.erroSubmit"], { variant: 'error' });
      return
    }

    if(empresa.cod === 378 && !this.isValidCPF(matricula)) {
      notify(lb["notify.cpf"], { variant: 'error' });
      return
    }

    if(!this.isEmail(email)) {
      notify(lb["notify.email"], { variant: 'error' });
      return
    }

    if(empresa.cod === 378) {
      let cpfExists = await this.cpfExists(matricula);
      // console.log(cpfExists);
      if(cpfExists) {
        return
      }
    }
    let ageInteger = age === null ? null : parseInt(age);
    let cargoFinal = (grupoClienteId === 26 ? 10493 : cargoSelec);

    return this.props.next({cargoFinal, unidadeSelec, nome, email, matricula, ageInteger, grupoClienteId})
  }

  onBlurInput = async(value) => {
    const {listUnid, listCargos} = this.state;
    try{
      const {data} = await api.get(`/getColaborator/${value.target.value}`)
      // console.log('data', data)
      if(data !== null && data !== '' && data !== undefined) {
          this.setState({nome: data.name})
          this.setState({email: data.email})
          this.setState({matricula: data.registration})
          let officeDescription = listCargos.filter(o => o.descricao ===  data.nameOffice);
          let unitDescription = listUnid.filter(u => u.descricao ===  data.unitname);
          // console.log('officeDescription', officeDescription)
          // console.log('unitDescription', unitDescription)
          
          this.setState({officeDescription: officeDescription[0]})

          this.setState({unitDescription: unitDescription[0]})
              
          // this.setState({inputCargo: officeDescription[0]})

          // this.setState({inputUnid: unitDescription[0]})

          this.setState({cargoSelec: officeDescription[0].id})
          this.setState({unidadeSelec: unitDescription[0].id})
      }
     
    }catch(err){
      // console.log(err)
    }
  }

  changeCargo = value => {
    const {listCargos} = this.state;
    // console.log('value.target.value', value.target.value)

    let officeDescription = listCargos.filter(o => o.descricao ===  value.target.value);

    this.setState({officeDescription: officeDescription[0]})
    this.setState({inputCargo: value.target.value})
  }

  changeUnidade = value => {
    const {listUnid} = this.state;
    console.log('value.target.value', value.target.value)
    let unitDescription = listUnid.filter(u => u.descricao ===  value.target.value);

    this.setState({unitDescription: unitDescription[0]})
    this.setState({inputUnid: value.target.value})
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  changeAge = value => {
    this.setState({age: value.target.value})
  }

  isEmail(email){

    let usuario = email.substring(0, email.indexOf("@"));
    let dominio = email.substring(email.indexOf("@")+ 1, email.length);

    if ((usuario.length >=1) &&
        (dominio.length >=3) &&
        (usuario.search("@")===-1) &&
        (dominio.search("@")===-1) &&
        (usuario.search(" ")===-1) &&
        (dominio.search(" ")===-1) &&
        (dominio.search(".")!==-1) &&
        (dominio.indexOf(".") >=1)&&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
        return true;
    } else {
        return false
    }

  }

  isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999" 
    ) {
        return false
    }

   

    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11

    
    if ((resto === 10) || (resto === 11))  resto = 0

    if (resto !== parseInt(cpf.substring(9, 10)) ) return false

    

    soma = 0
    for (var j = 1; j <= 10; j++) {
      soma = soma + parseInt(cpf.substring(j-1, j)) * (12 - j)
    };
    
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11))  resto = 0
    if (resto !== parseInt(cpf.substring(10, 11) ) ) return false
    return true
  }

  matricula () {
    const {
      empresa,
      matricula,
      lb,
    } = this.state;
    const {
      classes,
    } = this.props;

    return ( 
      <div>
          {
            empresa.cod === 378 ? 
            <TextField
            label={lb["label.matriculacpf"]}
            className={classes.textfield}
            name="matricula"
            id="formatted-text-mask-input"
            value={matricula}
            onChange={this.handleChange('matricula')}
            onBlur={value => this.onBlurInput(value)}
            variant="outlined"
            fullWidth
            
            InputProps={{
              inputComponent: TextMaskCustom
            }}
          />  
           :
           <TextField
            label={lb["label.matricula"]}
            className={classes.textfield}
            name="matricula"
            id="matricula"
            value={matricula}
            onChange={value => this.setState({matricula: value.target.value})}
            onBlur={value => this.onBlurInput(value)}
            variant="outlined"
            type="email"
            fullWidth
          /> 
          }
      </div>
    )
  }

  render() {
      const {
        falhaApiKey,
        empresa,
        nome,
        email,
        matricula,
        lb,
        modalTermo,
        listCargos,
        listUnid,
        // inputUnid,
        // inputCargo,
        age,
        officeDescription,
        unitDescription
      } = this.state;
      const {
        classes,
        handleSetLinguage
      } = this.props;
    return (
      <Grid container className={classes.container} >
        <Grid item style={{width: '100%', margin: '20px 0px'}}>
          <Grid item style={{width: '100%', display: 'flex'}}>
            <Grid item style={{width: '87%'}}>
              {/*eslint-disable-next-line*/}
              {(empresa.logo != null && empresa.logo != '') ? <img  style={{width: (empresa.logo.includes('ball') ? '65.15px' : empresa.logo.includes('cargill') ? '135.15px' : '157.15px'), height: '60.05px', cursor: 'pointer'}} src={require(`../../../../assets/images/logoEmpresas/${empresa.logo}`)} /> : <div></div>}
              <Typography className={classes.subTitle}>
                {lb["home.text1"]}
              </Typography>
              <Typography style={{marginTop: '15px', marginBottom: '8px'}}>
                {lb["home.text2"]}
              </Typography>
            </Grid>
            <Grid style={{display: 'flex', flexDirection: 'row', width: '13%', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
              <Grid style={{display: 'flex', alignItems: 'center', marginRight: '5px'}} onClick={() => handleSetLinguage('pt_BR')}>
                {/*eslint-disable-next-line*/}
                <img src={brazil} style={{width: '20px', height: '20px', cursor: 'pointer'}}/>
              </Grid>
              <Grid style={{display: 'flex', alignItems: 'center', marginRight:'2px'}} onClick={() => handleSetLinguage('en')}>
                {/*eslint-disable-next-line*/}
                <img src={eua} style={{width: '20px', height: '20px', cursor: 'pointer'}}/>
              </Grid>
              <Grid style={{background: 'none', border: 'none', outline: 'none', display: 'flex', alignItems: 'center', marginTop: '-2.5px'}} onClick={() => handleSetLinguage('es')}>
                {/*eslint-disable-next-line*/}
                <img src={esa} style={{width: '25px', height: '25px', cursor: 'pointer'}}/>
              </Grid>
            </Grid>
          </Grid>
          <Typography className={classes.empresaNome}>
            {empresa.nome}
          </Typography>
          {empresa.grupoClienteId !== 26 &&  <TextField
            label={lb["label.nome"]}
            className={classes.textfield}
            name="name"
            id="name"
            value={nome}
            onChange={value => this.setState({nome: value.target.value})}
            onBlur={value => this.onBlurInput(value)}
            variant="outlined"
            type="email"
            fullWidth
          /> }
          {empresa.grupoClienteId === 1 && 
            <TextField
            label={lb["label.age"]}
            className={classes.textfield}
            name="idade"
            id="idate-input"
            value={age}
            onChange={this.changeAge}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
          />  }

          <TextField
            label={lb["label.email"]}
            className={classes.textfield}
            name="email"
            id="email"
            value={email}
            onChange={value => this.setState({email: value.target.value})}
            onBlur={value => this.onBlurInput(value)}
            variant="outlined"
            type="email"
            fullWidth
          />
          {this.matricula()}
          
           {empresa.grupoClienteId !== 26 && 
           <FormControl className={classes.formControl} fullWidth variant="outlined">
            <Autocomplete
              value={officeDescription === '' || officeDescription === undefined || officeDescription === "" ? null :  officeDescription}
              id="combo-box-demo-3"
              onChange={ (value, option) => this.setState({cargoSelec:  option === null ? '' : option.id, officeDescription: option}) }
              options={listCargos}
              getOptionLabel={option => (option.descricao === null ? '' : option.descricao)}
              // inputValue={officeDescription}
              renderInput={cargoSelec => (
                <TextField {...cargoSelec} 
                label={lb["label.cargo"]} variant="outlined" fullWidth  />
              )}
              noOptionsText={lb["label.digite"]}
            />
          </FormControl> }
          <FormControl className={classes.formControl} fullWidth variant="outlined">
            <Autocomplete
              value={unitDescription === '' || unitDescription === undefined || unitDescription === "" ? null : unitDescription}
              id="combo-box-demo-4"
              onChange={ (value, option) => this.setState({unidadeSelec: option === null ? '' : option.id, unitDescription: option}) }
              options={listUnid}
              // inputValue={unitDescription}
              getOptionLabel={option => (option.descricao === null ? '' : option.descricao)}
              renderInput={unidadeSelec => (
                <TextField {...unidadeSelec} 
                label={lb["label.unidade"]} variant="outlined" fullWidth />
              )}
              noOptionsText={lb["label.digite"]}
            />
          </FormControl>
          <Grid style={{margin: '5px'}}>
            <Typography>
              {lb["home.termoClick"]}
              (<b style={{cursor: 'pointer'}} onClick={() => this.setState({modalTermo: true})}>{lb["home.click"]}</b>)
            </Typography>
          </Grid>
          <Button 
            fullWidth 
            disabled={falhaApiKey}
            variant="contained" 
            color="secondary"
            style={{color: '#fff'}} 
            onClick={() => this.handleSubmit()}
          >
            {falhaApiKey?
              <CircularProgress size={25} /> : lb["button.continuar"]
            }
          </Button>
        </Grid>
        <Modal
          open={modalTermo}
          className='termo'
          onClose={() => this.setState({modalTermo: false})}
        >
          <Grid style={{backgroundColor: "#fff", width: '50%', height: '65%', outline: 'none', padding: '20px', overflowX: 'auto'}}>
            <Typography className='termo-font'>
              <b>{lb["home.termo1"]}</b>
              <br/>
              {lb["home.termo2"]}
              <br/>
              {lb["home.termo3"]}
              <br/>
              {lb["home.termo4"]}
              <br/>
              {lb["home.termo5"]}
              <br/>
              {lb["home.termo6"]}
              <br/>
              <br/>
              <b>{lb["home.termo7"]}</b>
              <br/>
              {lb["home.termo8"]}
              <br/>
              {lb["home.termo9"]}
              <br/>
              {lb["home.termo10"]}
              <br/>
              {lb["home.termo11"]}
            </Typography>
          </Grid>
        </Modal>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  notify: (message, options) => dispatch(NotificationActions.notify(message, options)),
});

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(Material, { withTheme: true }),
)(Home);

